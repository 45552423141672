import { Injectable } from '@angular/core';
import { GraphQLResult, generateClient } from 'aws-amplify/api';
import { Observable, from, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

type queryInput = {
  statement: string;
  variables: { [key: string]: any };
  type: string;
  iam?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private client = generateClient();

  graphql<V>({ statement: query, variables, type, iam = false }: queryInput) {

    const payload: any = {
      query,
      authMode: iam ? 'iam' : 'userPool',
    }
    if (variables) {
      payload.variables = variables;
    }
    return from(this.client.graphql(payload) as Promise<GraphQLResult<object>>)
      .pipe(
        map(res => res.data[type as keyof object]),
        catchError((error: GraphQLResult<object> | Error) => {
          // graphql error
          if ('errors' in error) {
            const gqError = error.errors![0];
            throw new Error(`Graphql Error running query ${type}. Authmode ${iam ? 'IAM' : 'Cognito'}. Error: ${gqError?.message} ${gqError?.path ? gqError.path.toString() : ''}`);
            // normal error
          } else if ('stack' in error) {
            throw new Error(`Runtime error running query ${type}. Authmode ${iam ? 'IAM' : 'Cognito'}. Error: ${error.stack}}`);
            // fallback for something else
          } else {
            throw new Error(`Unknown Error running query ${type}. Authmode ${iam ? 'IAM' : 'Cognito'}. Error: ${error}}`);
          }
        })
      ) as Observable<V>
  }
}
