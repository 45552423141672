import { Injectable } from '@angular/core';
import { BehaviorSubject, ObservableInput, of, switchMap } from 'rxjs';
import { LayoutService } from '../layout/layout.service';
import { ApCognitoUser } from '../../models/user';
import { Dictionary, ViewValue } from '../../models/global';
import { ContentItemCollection } from '../content/content.service';
import { Resident } from '../resident/resident.service';
import { Facility } from '../facility/facility.service';
import { ResidentPreference } from 'src/app/modules/resident-profile/models/profile-form.model';


export type PreferenceCategories = Dictionary<ViewValue[]>;
/**
 * Register you State propery here
 */
interface AppState {
  currentRoute: BehaviorSubject<string[]>;
  currentResidentId: BehaviorSubject<string>;
  currentResident: BehaviorSubject<Resident>;
  currentResidentPreferences: BehaviorSubject<ResidentPreference>;
  currentFacilityId: BehaviorSubject<string>;
  currentFacility: BehaviorSubject<Facility>;
  currentUser: BehaviorSubject<ApCognitoUser>;
  contentItemCollection: BehaviorSubject<ContentItemCollection>;
  preferenceCategories: BehaviorSubject<PreferenceCategories>;
}

@Injectable({
  providedIn: 'root'
})
export class  AppStateService {

  isMobile$ = this.layoutService.isMobile$;
  responsive$ = this.layoutService.isResponsive$;

  constructor(
    private layoutService: LayoutService
  ) {}

  /**
   * State is our main Observable that will never be completed.
   * It consist of a collection of child values that are of type BehaviorSubject.
   * If a property doesn't exists yet, the setState will create the required BehaviorSubject.
   * When the state is cleared (on logout), the child properties Observables are completed and removed.
   *
   * By using the `get$` function to subscripe to a child Observable, we will never run into
   * a reference collision, because the State will never be recreated during the app.
   * Or use `get` to get the direct value of the BehaviorSubject
   */
  private _state = new BehaviorSubject<Partial<AppState>>({})
  private state$ = this._state.asObservable();

  setState(key: keyof AppState, val: any) {
    // console.log('Calling SetState for ', key, val);

    // if property doesn't exist in state, createa new behaviorSubject for it and publish the new state
    if (!this._state.value[key]) {
      this._state.value[key] = new BehaviorSubject(null);
      this._state.next(this._state.value);
    }
    this._state.value[key].next(val);
  }

  /**
   * Returns the current Observable of the state property
   */
  get$<T>(key: keyof AppState) {
    return this.state$.pipe(switchMap<any, ObservableInput<T>>(val => val[key] ? val[key] : of<T>(null)))
  }

  /**
   * Returns the current value of the state property
   */
  get<T>(key: keyof AppState): T {
    return this._state.value[key] ? this._state.value[key].value as T: null;
  }

  /**
   * On logout we need to clear the state in case browsers are not refresh, flushing the cache
   */
  clearState() {
    for (const key of Object.keys(this._state.value) ) {
      this._state.value[key as keyof AppState].complete();
      delete this._state.value[key as any as keyof AppState];
    }
  }

  /**
   * Reinitialise the (Behaviour)Subjects because they where completed and no longer emitting values
   */
  initState() {
    this.clearState();
  }
}
