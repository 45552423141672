import { ActivatedRoute } from '@angular/router';

export const getRecursiveParams = (route: ActivatedRoute, params: any): any => {
  params = {
    ...params,
    ...route.snapshot.params,
  }
  if (route.firstChild) {
    return getRecursiveParams(route.firstChild, params)
  } else {
    return params
  }

};